<script>
import { sameAs } from 'vuelidate/lib/validators';
import BasePasswordInput from '@shared/components/BasePasswordInput.vue';
import APICustomer from '@school/services/API/Customer';

export default {
  components: { BasePasswordInput },
  data() {
    return {
      isLoading: false,
      customer: {
        old_password: '',
        new_password: '',
        confirm_new_password: '',
      },
    };
  },
  validations: {
    customer: {
      confirm_new_password: {
        sameAs: sameAs('new_password'),
      },
    },
  },
  methods: {
    handle() {
      const customer = { ...this.customer };

      this.isLoading = true;
      APICustomer.updatePassword(customer)
        .then(() => {
          this.$showMessage.success();
          Object.assign(this.customer, this.$options.data.bind(this)().customer);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
    <div class="columns is-multiline">
      <div class="column is-12">
        <b-field label="Ancien mot de passe">
          <b-input
            v-model="customer.old_password"
            type="password"
            required
            password-reveal
          />
        </b-field>
      </div>
      <div class="column is-12">
        <b-field label="Nouveau mot de passe">
          <BasePasswordInput
            v-model="customer.new_password"
          />
        </b-field>
      </div>
      <div class="column is-12">
        <b-field
          label="Confirmez votre nouveau mot de passe"
          v-bind="$getErrorProps($v.customer.confirm_new_password, ['sameAs'])"
        >
          <b-input
            v-model="customer.confirm_new_password"
            type="password"
            minlength="8"
            maxlength="32"
            required
            placeholder="8 caractères minimum"
            password-reveal
            :use-html5-validation="false"
          />
        </b-field>
      </div>
    </div>

    <b-field class="has-text-right">
      <b-button type="is-primary is-custom" native-type="submit" :loading="isLoading">
        Mettre à jour
      </b-button>
    </b-field>
  </form>
</template>
